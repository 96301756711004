.wrapper {
  width: 100%;
  display: grid;
  gap: 12px;
}

.horizontal {
  grid-template-columns: repeat(3, 1fr);
}

.vertical {
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
}

.item {
  min-width: 0;
  width: 100%;
  height: 100%;
  color: var(--white);
  font-size: 24px;
  font-weight: bold;
  aspect-ratio: 1;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  cursor: pointer;
}

.image {
  min-width: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 20px;
  border: 2px solid black;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
}

.horizontal div:first-of-type {
  grid-column: 1 / -1;
}

.vertical div:first-of-type {
  grid-row: 1 / -1;
}

.more-wrapper {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  align-items: center;
  justify-items: center;
}
