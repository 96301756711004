.wrapper {
  display: inline-block;
  border-radius: 20px;
  padding: 2px 10px;
  background-color: var(--white);
  z-index: 1;
}

.imageLabel {
  display: flex;
}
