.button {
  border-radius: 100px;
  border: 2px solid black;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 2px 2px black;
  box-sizing: border-box;
  font-family: "Graphik", sans-serif;
}

.button.hover:hover {
  background-color: var(--black);
  color: var(--white);
}

.button:disabled {
  background-color: var(--gray);
  color: var(--white);
}

.black {
  background-color: var(--black);
  color: var(--white);
}

.primary,
.dark-blue {
  background-color: var(--dark-blue);
  color: var(--white);
}

.light-blue {
  background-color: var(--light-blue);
  color: var(--black);
}

.yellow {
  background-color: var(--yellow);
  color: var(--black);
}

.white {
  background-color: var(--white);
  color: var(--black);
}

.sm {
  font-size: 16px;
  padding: 4px 20px;
}

.md {
  font-size: 16px;
  padding: 8px 32px !important;
}

.lg {
  font-size: 16px;
  width: 100%;
  padding: 8px 16px;
}

.decline {
  color: var(--dark-blue);
  border: none;
  background-color: var(--white);
  box-shadow: none;
  font-size: 12px;
  padding: 12px 20px;
}

.accept {
  color: var(--black);
  background-color: var(--yellow);
  box-shadow: none;
  font-size: 12px;
  padding: 12px 20px;
}

.cancel {
  color: var(--black);
  background-color: var(--light-blue);
  box-shadow: none;
  font-size: 12px;
  padding: 12px 20px;
}
