.mobileWrapper {
  padding: 20px 16px;
  background-color: var(--dark-blue);
  border-bottom: 2px solid var(--black);
}
.mobileTitle {
  color: var(--white);
  display: flex;
  justify-content: space-between;
  /* max-width: 375px;
  margin: auto; */
}

.mobileFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 21px;
  /* max-width: 375px;
  margin: auto; */
}

.filterSVG {
  border: 1px solid red;
}

/* MD */
.mdWrapper {
  background-color: var(--dark-blue);
  border-bottom: 2px solid var(--black);
  padding: 20px 24px;
}

.mdTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  margin: auto;
}

.mdRightButtons {
  display: flex;
  gap: 12px;
}
.mdLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.mdMid {
  display: flex;
  justify-content: center;
  max-width: 650px;
  margin: auto;
}

.mdMid ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
  padding: 0;
}
.mdMid li {
  list-style-type: none;
}
.mdMid a {
  color: var(--white);
}
.mdMid a:hover {
  color: var(--yellow) !important;
}

.mdBot {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  max-width: 650px;
  margin: auto;
}

/* LG */

.lgWrapper {
  background-color: var(--dark-blue);
  border-bottom: 2px solid var(--black);
  padding: 28px calc(-54.28571px + 14.88095vw);
}

.lgTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: auto;
}

.lgRightButtons {
  display: flex;
  gap: 12px;
}

.lgBot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 43px;
  max-width: 800px;
  margin: auto;
}

.lgNavLeft ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;
  padding: 0;
}

.lgNavLeft li {
  list-style-type: none;
}

.lgNavLeft a {
  color: var(--white);
}
.lgNavLeft a:hover {
  color: var(--yellow) !important;
}

.lgFilterRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
/* XL */
.xlWrapper {
  padding: 28px 41px;
  background-color: var(--dark-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--black);
}

.xlLeft {
  display: flex;
  align-items: center;
}
.xlRight ul {
  display: flex;
  gap: 28px;
  align-items: center;
}

.xlRight li {
  list-style-type: none;
}
.xlRight a {
  color: var(--white);
}
.xlRight a:hover {
  color: var(--yellow) !important;
  /* border-bottom: 2px solid var(--yellow); */
  /* padding-bottom: 6px; */
}
