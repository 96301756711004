.wrapper {
  display: inline-block;
  box-shadow: 5px 5px 2px var(--black);
  border-radius: 20px;
  border: 2px solid var(--black);
  padding: 12px 28px;
  background-color: var(--white);
}

.wrapper nav {
  display: inline-block;
}

.nav {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
}

.nav li {
  list-style-type: none;
}

.nav li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.nav li:nth-of-type(3) a {
  position: relative;
}

.nav li:nth-of-type(3) div:first-of-type {
  position: absolute;
  bottom: 80%;
}

.nav li:nth-of-type(3) div:nth-of-type(2) {
  margin-top: 30px;
}
