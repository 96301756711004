.meet-up-info-card {
  padding: 20px;
  height: 389px;
  border: 2px solid #000000;
  border-radius: 20px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 0.1fr 2fr;
}

.meet-up-info-card .title-container {
  margin-bottom: 8px;
}

.title-container {
  grid-column: 1/-1;
}

.map-container {
  width: 100%;
  height: 230px;
  border: 2px solid black;
  margin-top: 10px;
  border-radius: 20px;
  grid-column: 1/-1;
}

@media screen and (min-width: 1440px) {
  .meet-up-info-card {
    height: 418px;
    padding: 16px;
  }

  .map-container {
    width: 100%;
    height: 259px;
    margin-top: 12px;
  }

  .time-container,
  .date-container,
  .location-container {
    grid-template-columns: 0.1fr 1.7fr;
  }
}
