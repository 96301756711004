.wrapper {
  display: grid;
}

.header {
  width: 50%;
  border: none;
  display: flex;
  align-content: stretch;
}

.btn {
  padding: 4px 12px;
  border: 2px solid var(--black);
  color: var(--black);
  background-color: var(--light-blue);
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--black);
  color: white;
}

.minus {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.plus {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ipt {
  width: 100%;
  height: 100%;
  font-size: 24px;
  padding: 4px 12px;
  box-sizing: border-box;
  border-top: 2px solid var(--black);
  border-right: none;
  border-bottom: 2px solid var(--black);
  border-left: none;
  border-radius: 0;
  color: var(--black);
  background-color: var(--white);
  text-align: center;
}

.ipt:focus {
  outline: none;
}

.footer {
  word-break: break-all;
}
