.transactionCard h3,
.transactionCard p {
  margin: 0;
  padding: 4px 0;
}

.transactionCard {
  border-radius: 20px;
  border: 2px solid var(--black);
  padding: 12px;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  column-gap: 12px;
  row-gap: 8px;
  transition: transform 0.1s ease;
  cursor: pointer;
  background-color: var(--white);
}

.transactionCard:hover {
  box-shadow: 5px 5px 2px var(--black);
  transform: scale(0.98);
}

.imageContainer {
  position: relative;
  overflow: hidden;
  min-width: 105px;
  height: 156px;
  border-radius: 10px;
  padding: 0;
  grid-row: 1 / -3;
  border: 2px solid var(--black);
}

.transactionCard img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.productInformation {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.productInformation div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  grid-column: 2 / 3;
  display: flex;
  justify-content: end;
  align-items: end;
  z-index: 10;
}

.productInformation .sellerContainer,
.productInformation .quantity {
  justify-content: flex-start;
  gap: 3px;
}

.productInformation h3,
.productInformation .sellerContainer p:last-child {
  color: #0b5cbb;
}

.productInformation button {
  align-self: end;
  align-content: end;
}
