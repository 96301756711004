:root {
  --dark-blue: #1c2aae;
  --gray: #404040;
  --light-gray: #757575;
  --light-blue: #c1d6ff;
  --yellow: #ffbf44;
  --black: #000000;
  --white: #ffffff;
  --blue: #569ae0;
  --bg-gray: #e8edf8;
  --error: #f23737;
}
